import React, { Component } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
// import Img from "gatsby-image";
import Header from "@components/header";
import Layout from "@components/layout";
import SEO from "@components/seo";
// import GlobalFooter from "../components/global-footer/index.js";
// import { RichText } from "prismic-reactjs";
import StoreContext from "@context/store.js";

import "@scss/shop.scss";
import "@scss/page.scss";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideIdx: 0,
      variantId: props.data.product.variants[0].shopifyId,
      variantPrice: props.data.product.variants[0].price,
      buttonText: "Add to Cart",
      addToCartDisabled: false,
    };
    this.changeSlide = this.changeSlide.bind(this);
  }
  componentDidMount() {
    let { props } = this;
    let firstVariant = get(props, "data.product.variants[0]");
    // if (firstVariant.title === "Default Title") {
    //   this.setState({
    //     variantId: firstVariant.shopifyId,
    //     variantPrice: firstVariant.price
    //   });
    // } else {
    //   this.setState({
    //     variantPrice: firstVariant.price,
    //     addToCartDisabled: true
    //   });
    // }
  }
  changeSlide(e, newSlideIdx) {
    e.preventDefault();
    this.setState({ activeSlideIdx: newSlideIdx });
  }
  render() {
    const { data } = this.props;
    const { variantId } = this.state;
    const product = get(data, "product", {});
    // let globals = get(data, "prismic.globals", {});
    const variantsOnSale = product.variants.filter((variant) => {
      return variant.compareAtPrice !== null;
    });
    const onSale = variantsOnSale.length > 0;
    // return (
    //   <div>
    //     <h1>hi</h1>
    //   </div>
    // );
    return (
      <Layout>
        <Header isInterior={true} />
        <SEO title="Product" />
        <StoreContext.Consumer>
          {(store) => {
            return (
              <section className="shop page">
                <div className="shop__content">
                  <div className="shop__product-main">
                    <div className="shop__product-photos">
                      <ul className="shop__product-photos-large">
                        {product.images &&
                          product.images.map((photo, idx) => {
                            let { activeSlideIdx } = this.state;
                            if (activeSlideIdx !== idx) {
                              return null;
                            }
                            return (
                              <li key={idx}>
                                <img src={photo.originalSrc} />
                              </li>
                            );
                          })}
                      </ul>
                      {product.images.length > 1 && (
                        <ul className="shop__product-photos-thumbnails">
                          {product.images.map((photo, idx) => {
                            return (
                              <li key={idx}>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    this.changeSlide(e, idx);
                                  }}
                                >
                                  <img src={photo.originalSrc} />
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    <div className="shop__product-details">
                      <h3>{product.title}</h3>
                      <strong>${Math.round(this.state.variantPrice)}</strong>
                      {onSale && (
                        <span className="shop__product-details--on-sale">
                          On Sale!
                        </span>
                      )}
                      <div className="shop__product-variants">
                        {product.variants.map((variant, idx) => {
                          let altText = !variant.availableForSale
                            ? `${variant.title} Sold Out`
                            : variant.title;
                          let isDisabled = !variant.availableForSale;
                          if (variant.title === "Default Title") {
                            return null;
                          }
                          return (
                            <React.Fragment key={idx}>
                              <input
                                id={variant.id}
                                name={`temp`}
                                type="radio"
                                value={variant.shopifyId}
                                disabled={isDisabled}
                                checked={variantId == variant.shopifyId}
                                onChange={(e) => {
                                  this.setState({
                                    variantId: e.target.value,
                                    variantPrice: variant.price,
                                    addToCartDisabled: false,
                                  });
                                }}
                              />
                              <label title={altText} htmlFor={variant.id}>
                                {variant.title}
                              </label>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <button
                        type="submit"
                        className="shop__product-add-to-cart"
                        disabled={this.state.addToCartDisabled}
                        onClick={() => {
                          this.setState({ buttonText: "Adding..." });
                          store.addVariantToCart(
                            this.state.variantId,
                            1,
                            () => {
                              this.setState({ buttonText: "Added!" }, () => {
                                store.toggleCartOpen();
                                setTimeout(() => {
                                  this.setState({ buttonText: "Add to Cart" });
                                }, 2000);
                              });
                            }
                          );
                        }}
                      >
                        {this.state.buttonText}
                      </button>
                      <div
                        className="shop__product-copy shop__product-copy"
                        dangerouslySetInnerHTML={{
                          __html: product.descriptionHtml,
                        }}
                      />
                      <div className="shop__product-description">
                        {/* <RichText render={globals.product_detail_term_text} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          }}
        </StoreContext.Consumer>
      </Layout>
    );
  }
}

export default ProductDetail;

export const productDetailQuery = graphql`
  query productDetailQuery($handle: String) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      description
      descriptionHtml
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
      }
      images {
        originalSrc
      }
    }
  }
`;
